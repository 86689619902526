<template>
    <div data-app>
        <div class="row">
            <div class="col">
                <v-card>
                    <v-card-title>
                        Crear Orden
                    </v-card-title>
                    <v-card-text>
                        <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation
                        >
                        <div class="row">
                            <div class="col-md-6">
                                <v-select
                                v-model="orderType"
                                :items="orderTypesList"
                                :rules="[v => !!v || 'Debes elegir un tipo de pedido']"
                                label="Order Type"
                                required
                            ></v-select>
                            </div>
                        </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <v-text-field
                                        v-model="customer.firstname"
                                        :counter="200"
                                        :rules="defaultRequiredRules"
                                        label="Nombre Cliente"
                                        required
                                    ></v-text-field>
                                </div>
                                <div class="col-md-6">
                                    <v-text-field
                                        v-model="customer.lastname"
                                        :counter="200"
                                        label="Apellidos Cliente"
                                        required
                                    ></v-text-field>
                                </div>
                            </div>
                            <v-text-field
                                v-model="customer.email"
                                :counter="200"
                                :rules="emailRules"
                                label="E-mail Cliente"
                                required
                            ></v-text-field>
                            <div class="row">
                                <div class="col-md-4">
                                    <v-text-field
                                        v-model="address.street"
                                        :counter="200"
                                        :rules="defaultRequiredRules"
                                        label="Calle"
                                        required
                                    ></v-text-field>
                                </div>
                                <div class="col-md-4">
                                    <v-text-field
                                        v-model="address.number"
                                        :counter="50"
                                        :rules="defaultRequiredRules"
                                        label="Número"
                                        required
                                    ></v-text-field>
                                </div>
                                <div class="col-md-4">
                                    <v-text-field
                                        v-model="address.dpto"
                                        :counter="100"
                                        :rules="defaultRequiredRules"
                                        label="Número Depto/Piso/Oficina"
                                        required
                                    ></v-text-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <v-text-field
                                        v-model="address.city"
                                        :counter="200"
                                        :rules="defaultRequiredRules"
                                        label="Ciudad de dirección Cliente"
                                        required
                                    ></v-text-field>
                                </div>
                                <div class="col-md-4">
                                    <v-text-field
                                        v-model="address.province"
                                        :counter="200"
                                        :rules="defaultRequiredRules"
                                        label="Provincia de dirección Cliente"
                                        required
                                    ></v-text-field>
                                </div>
                                <div class="col-md-4">
                                    <v-text-field
                                        v-model="address.state"
                                        :counter="200"
                                        :rules="defaultRequiredRules"
                                        label="Región de dirección Cliente"
                                        required
                                    ></v-text-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <v-text-field
                                        v-model="address.dni"
                                        :counter="100"
                                        :rules="defaultRequiredRules"
                                        label="RUT Cliente"
                                        required
                                    ></v-text-field>
                                </div>
                                <div class="col-md-6">
                                    <v-text-field
                                        v-model="address.phone"
                                        :rules="defaultRequiredRules"
                                        label="Teléfono Cliente"
                                        required
                                    ></v-text-field>
                                </div>
                            </div>
                            <v-card class="mt-2 mb-2">
                                <v-card-text>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <h3 class="headline">Añadir productos</h3>
                                            <v-text-field
                                                v-model="newProducto.product"
                                                label="Nombre producto"
                                                required
                                            ></v-text-field>
                                            <v-text-field
                                                v-model="newProducto.sku"
                                                label="SKU producto"
                                                required
                                            ></v-text-field>
                                            <v-text-field
                                                v-model="newProducto.ean"
                                                label="EAN producto"
                                                required
                                            ></v-text-field>
                                            <v-text-field
                                                v-model="newProducto.quantity"
                                                label="Cantidad de producto"
                                                type="number"
                                                required
                                            ></v-text-field>
                                            <v-text-field
                                                v-model="newProducto.total" 
                                                label="Total del producto"
                                                type="number"
                                                required
                                            ></v-text-field>
                                            <v-btn
                                                color="info"
                                                dark
                                                class="mr-4"
                                                @click="addNewProducto"
                                            >
                                                Añadir
                                            </v-btn>
                                        </div>
                                        <div class="col-md-6">
                                            <v-simple-table dense>
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th v-for="(h,i) in headersProductos" :key="i" class="text-left">{{h}}</th>
                                                        <th class="text-right">Acciones</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index) in itemsProductos" :key="index">
                                                        <td>{{ item.product }}</td>
                                                        <td>{{ item.sku }}</td>
                                                        <td>{{ item.ean }}</td>
                                                        <td>{{ item.quantity }}</td>
                                                        <td>{{ item.total }}</td>
                                                        <td class="text-right">
                                                            <v-btn @click="eliminarFila(item)" text icon color="red">
                                                                <v-icon>mdi-close</v-icon>
                                                            </v-btn>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                            </v-simple-table>
                                        </div>
                                    </div>
                                </v-card-text>
                            </v-card>
                            <hr>
                            <v-select
                                v-model="source"
                                :items="sourcesList"
                                :rules="[v => !!v || 'Debes elegir un source']"
                                label="Source"
                                required
                            ></v-select>

                            <v-select
                                v-model="store"
                                :items="storesList"
                                :rules="[v => !!v || 'Debes elegir una store']"
                                label="Store"
                                required
                            ></v-select>

                            <v-text-field
                                v-model="order_reference"
                                :counter="250"
                                :rules="defaultRequiredRules"
                                label="Order Reference"
                                required
                            ></v-text-field>

                            <v-text-field
                                v-model="shipping_number"
                                :counter="45"
                                :rules="defaultRequiredRules"
                                label="Número de despacho"
                                required
                            ></v-text-field>

                            <v-text-field
                                v-model="total_products"
                                :rules="defaultRequiredRules"
                                type="number"
                                label="Total products"
                                required
                            ></v-text-field>
                            
                            <v-text-field
                                v-model="total_shipping"
                                :rules="defaultRequiredRules"
                                label="Total shipping"
                                type="number"
                                required
                            ></v-text-field>

                            <v-text-field
                                v-model="total_discount"
                                :rules="defaultRequiredRules"
                                label="Total discount"
                                type="number"
                                required
                            ></v-text-field>

                            <v-text-field
                                v-model="total_paid"
                                :rules="defaultRequiredRules"
                                label="Total paid"
                                type="number"
                                required
                            ></v-text-field>
                            
                            <v-text-field
                                v-model="total_order"
                                :rules="defaultRequiredRules"
                                label="Total order"
                                type="number"
                                required
                            ></v-text-field>

                            <v-text-field
                                v-show="$store.state.passport.user.default_store_name == 'Veggo'"
                                v-model="payment_customer_id"
                                :rules="defaultRequiredRules"
                                label="Flow customer ID"
                                type="text"
                                required
                            ></v-text-field>

                            <v-datetime-picker label="Source Date" v-model="source_date"> </v-datetime-picker>
                            
                            <v-btn
                                color="blue-grey"
                                class="mr-4"
                                @click="reset"
                            >
                                Resetear
                            </v-btn>
                        </v-form>
                        <br><br>
                        <v-btn
                            :loading="loading"
                            :disabled="loading"
                            color="blue-grey"
                            class="ma-2"
                            @click="sendForm"
                        >
                            Crear orden
                            <v-icon right dark>mdi-cloud-upload</v-icon>
                        </v-btn>
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
export default {
    data(){
        return {
            loading: false,
            valid: true,
            payment_customer_id: '',
            customer: {
                email: '',
                firstname: '',
                lastname: '',
            },
            address: {
                street: '',
                number: '',
                dpto: '',
                city: '',
                province: '',
                state: '',
                dni: '',
                phone: '',
            },
            source_date: undefined,
            source: undefined,
            sourcesList: [
            ],
            orderType: undefined,
            orderTypesList: [
                
            ],
            store: undefined,
            storesList: [
            ],
            defaultRequiredRules: [
                v => !!v || 'Este campo es obligatorio',
            ],
            emailRules: [
                v => !!v || 'Este campo de obligatorio',
                v => /.+@.+\..+/.test(v) || 'Debes poner un email válido',
            ],
            headersProductos: ['product', 'sku', 'ean', 'quantity', 'total'],
            itemsProductos: [],
            newProducto: {
                product: '',
                sku: '',
                ean: '',
                quantity: '',
                total: '',
            },
            order_reference: '',
            shipping_number: '',
            total_products: '',
            total_shipping: '',
            total_discount: '',
            total_paid: '',
            total_order: undefined,
        }
    },
    methods: {
        sendForm()
        {   
            
            var vm = this;
            let source_date_formateado = this.$moment(this.source_date).format('YYYY-MM-DD HH:mm');
            
            if(!this.itemsProductos.length){
                vm.$bvToast.toast("Debes añadir al menos un producto a la orden de pedido", {
                    title: `Acción concretada`,
                    variant: 'danger',
                    solid: true,
                    toaster: 'b-toaster-bottom-center'
                });
                return;
            }
            if(!source_date_formateado){
                vm.$bvToast.toast("Source date invalido", {
                    title: `Acción concretada`,
                    variant: 'danger',
                    solid: true,
                    toaster: 'b-toaster-bottom-center'
                });
                return;
            }
            
            if(this.$refs.form.validate()){
                this.loading = true;
                this.axios({
                    url: 'orders/create',
                    method: 'POST',
                    data: {
                        customer: this.customer,
                        address: this.address,
                        source: this.source,
                        store: this.store,
                        order_detail: this.itemsProductos,
                        order_reference: this.order_reference,
                        shipping_number: this.shipping_number,
                        total_products: this.total_products,
                        total_shipping: this.total_shipping,
                        total_discount: this.total_discount,
                        total_paid: this.total_paid,
                        source_date: source_date_formateado,
                        payment_customer_id: this.payment_customer_id,
                        total_order: this.total_order,
                        order_type: this.orderType,
                    }
                }).then( () => {
                    vm.$bvToast.toast("Orden ingresada correctamente", {
                        title: `Acción concretada`,
                        variant: 'success',
                        solid: true,
                        toaster: 'b-toaster-bottom-center'
                    });
                    vm.loading = false;


                }).catch( error => {
                    console.log(error);
                    vm.loading = false;
                });
                
            }
            
        },
        validate () {
            if (this.$refs.form.validate()) {
                //this.snackbar = true
            }
        },
        reset () {
            this.$refs.form.reset()
        },
        resetValidation () {
            this.$refs.form.resetValidation()
        },
        addNewProducto(){
            if(
                !this.newProducto.product ||
                !this.newProducto.sku ||
                !this.newProducto.ean ||
                !this.newProducto.quantity ||
                !this.newProducto.total
            ){
                return
            }
            this.itemsProductos.push(this.newProducto);
            this.newProducto = {
                product: '',
                sku: '',
                ean: '',
                quantity: '',
                total: '',
            };
        },
        eliminarFila(item)
        {
            const index = this.itemsProductos.indexOf(item);
            this.itemsProductos.splice(index,1);
        }
    },
    created(){
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Dashboard Ordenes", route: "/ordenes" },
            { title: "Crear Orden ", route: "crear" },
        ]);
        var vm = this;
        this.axios({
            url: 'orders/stores/data_select_input',
            method: 'GET',
        }).then( response => {
            vm.storesList = response.data;
        }).catch( error => {
            console.log(error);
        })
        this.axios({
            url: 'orders/order_types/data_select_input',
            method: 'GET',
        }).then( response => {
            vm.orderTypesList = response.data;
        }).catch( error => {
            console.log(error);
        })
        this.axios({
            url: 'orders/sources/data_select_input',
            method: 'GET',
        }).then( response => {
            vm.sourcesList = response.data;
        }).catch( error => {
            console.log(error);
        })
    }
}
</script>